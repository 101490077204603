import type {
  User,
  Vendor,
  Project,
  Vehicle,
  CreditCard,
  Customer,
  CustomerContactItems
} from 'api/types';
import axios from 'api/axios';

export function getAllVendors(
  netVendors = '',
  pageNumber = '',
  pageSize = '',
  searchText = '',
  status = true,
  isQuickbookRegistered = true
) {
  const params: {
    netVendors?: string;
    pageNumber?: string;
    pageSize?: string;
    searchText?: string;
    status: string;
    isQuickbookRegistered: string;
  } = {
    netVendors,
    pageNumber,
    pageSize,
    searchText,
    status: status ? 'true' : 'false',
    isQuickbookRegistered: isQuickbookRegistered ? 'true' : 'false'
  };
  return axios.get(`/getAllVendors?${new URLSearchParams(params)}`).then((res) => res.data);
}

export function getAllCreditCards(
  isAll = false,
  pageNumber = '',
  pageSize = '',
  searchText = '',
  status = true
) {
  const params: {
    isAll?: string;
    pageNumber: string;
    pageSize: string;
    searchText: string;
    status: string;
  } = {
    pageNumber,
    pageSize,
    searchText,
    status: status ? 'true' : 'false'
  };
  if (isAll) {
    params.isAll = 'true';
  }
  return axios.get(`/credit-cards?${new URLSearchParams(params)}`).then((res) => res.data);
}

export function getAllUsers(
  role = '',
  pageNumber = '',
  pageSize = '',
  searchText = '',
  status = true
) {
  const params: {
    role?: string;
    pageNumber?: string;
    pageSize?: string;
    searchText?: string;
    status: string;
  } = {
    role,
    pageNumber,
    pageSize,
    searchText,
    status: status ? 'true' : 'false'
  };
  return axios.get(`/users?${new URLSearchParams(params)}`).then((res) => res.data);
}

export function getAllCustomers(
  isAll = false,
  pageNumber = '',
  pageSize = '',
  searchText = '',
  status = true
) {
  const params: {
    isAll?: string;
    pageNumber: string;
    pageSize: string;
    searchText: string;
    status: string;
  } = {
    pageNumber,
    pageSize,
    searchText,
    status: status ? 'true' : 'false'
  };
  if (isAll) {
    params.isAll = 'true';
  }
  return axios.get(`/customers?${new URLSearchParams(params)}`).then((res) => res.data);
}

export function getAllVehicleTypes(
  isAll = false,
  pageNumber = '',
  pageSize = '',
  searchText = '',
  status = true
) {
  const params: {
    isAll?: string;
    pageNumber: string;
    pageSize: string;
    searchText: string;
    status: string;
  } = {
    pageNumber,
    pageSize,
    searchText,
    status: status ? 'true' : 'false'
  };
  if (isAll) {
    params.isAll = 'true';
  }
  return axios.get(`/vehicle-type?${new URLSearchParams(params)}`).then((res) => res.data);
}

export function updateUser(userData: User, user_id: string) {
  return axios.patch(`/users/${user_id}`, { userData }).then((res) => res.data);
}

export function updateCustomer(customerData: Customer, customer_id: string) {
  return axios
    .patch(`admin-workspace/customers/${customer_id}`, { data: customerData })
    .then((res) => res.data);
}

export function updateCustomerContact(customerContact: CustomerContactItems, customer_id: string) {
  return axios
    .patch(`/customers/contact/${customer_id}`, { customerContact })
    .then((res) => res.data);
}

export function updateVendor(vendorData: Vendor, vendorId: number) {
  return axios
    .patch(`admin-workspace/vendors/${vendorId}`, { data: vendorData })
    .then((res) => res.data);
}

export function postVendor(vendorData: Vendor) {
  return axios.post('/vendors', { vendorData }).then((res) => res.data);
}

export function createUser(userData: User) {
  return axios.post(`/users`, { userData }).then((res) => res.data);
}

export function createCustomer(customerData: Customer) {
  return axios.post(`/customers`, { customerData }).then((res) => res.data);
}

export function customerContact(customerContact: CustomerContactItems) {
  return axios.post(`/customers/contact`, { customerContact }).then((res) => res.data);
}

export function getAllProjects(
  isAll = false,
  pageNumber = '',
  pageSize = '',
  searchText = '',
  status = false
) {
  const params: {
    isAll?: string;
    pageNumber: string;
    pageSize: string;
    searchText: string;
    status: string;
  } = {
    pageNumber,
    pageSize,
    searchText,
    status: status ? 'true' : 'false'
  };
  if (isAll) {
    params.isAll = 'true';
  }
  return axios.get(`/projects?${new URLSearchParams(params)}`).then((res) => res.data);
}

export function updateProject(project: Project, projectId: number) {
  return axios
    .patch(`admin-workspace/projects/${projectId}`, { data: project })
    .then((res) => res.data);
}

export function postProject(project: Project) {
  return axios.post('/projects', { project }).then((res) => res.data);
}

export function createVehicle(vehicleType: Vehicle) {
  return axios.post(`/vehicle-type`, { vehicleType }).then((res) => res.data);
}

export function updateVehicle(vehicleType: Vehicle, vehicle_type_id: string) {
  return axios
    .patch(`admin-workspace/vehicle-type/${vehicle_type_id}`, { data: vehicleType })
    .then((res) => res.data);
}

export function createCreditCard(creaditCard: CreditCard) {
  return axios.post(`/credit-cards`, { creaditCard }).then((res) => res.data);
}

export function updateCreditCard(creditCard: CreditCard, card_id: string) {
  return axios
    .patch(`admin-workspace/credit-cards/${card_id}`, { data: creditCard })
    .then((res) => res.data);
}

export function deleteCustomerContact(customerContactUUID: string) {
  return axios.delete(`/customers/contact/${customerContactUUID}`).then((res) => res.data);
}

export function deleteCustomer(customer_id: string) {
  return axios.delete(`/customer/${customer_id}`).then((res) => res.data);
}

export function getQBAlerts() {
  return axios.get(`/qb-alerts`).then((res) => res.data);
}

export function getVendorById(id: string) {
  return axios.get(`admin-workspace/vendors/${id}`).then((res) => res.data);
}

export function getJobById(id: string) {
  return axios.get(`admin-workspace/projects/${id}`).then((res) => res.data);
}

export function getBillById(id: string) {
  return axios.get(`admin-workspace/bills/${id}`).then((res) => res.data);
}

export function getCustomerById(id: string) {
  return axios.get(`admin-workspace/customers/${id}`).then((res) => res.data);
}

export function deleteQBAlert(onexerpId: string) {
  return axios.delete(`/qb-alerts/${onexerpId}`).then((res) => res.data);
}

export function vendorQBApprove(onexerpId: string) {
  return axios.patch(`vendors/quickbook/approval/${onexerpId}`).then((res) => res.data);
}
