import React from 'react';
import Select from 'react-select';
import ErrorMessage from 'components/NewLayout/ErrorMessage';

export interface DropdownOptionType {
  value: string;
  id: string | number;
  color?: string;
  label?: string;
}

export default function DropDown({
  labelText,
  name,
  value,
  data,
  onChange,
  isRequired,
  className,
  optionText,
  disabled,
  validator,
  validationString,
  onBlur,
  onKeyDown
}: {
  labelText?: string;
  name: string;
  value?: string | number;
  onChange: any;
  data?: DropdownOptionType[];
  isRequired?: boolean | string;
  className?: string;
  optionText?: string;
  disabled?: boolean;
  validator?: any;
  validationString?: string;
  onBlur?: any;
  onKeyDown?: any;
}) {
  const selectOptions = data?.map((option) => ({
    value: option.id,
    label: option.value,
    color: option.color
  }));
  const isError = validator?.current?.message(name, value, validationString || 'required');
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.data.color ?? '',
      color: state.data.color ? '#fff' : '#000',
      textAlign: 'left'
    })
  };
  return (
    <>
      {labelText == undefined ? (
        ''
      ) : (
        <label className="form-label">
          {labelText}
          {isRequired != false ? <span className="required">*</span> : ''}
        </label>
      )}
      <Select
        name={name}
        className={`${className} ${isRequired && isError ? 'red-border' : ''} react-select`}
        onChange={(selectedOption: any) =>
          onChange({ target: { name, value: selectedOption.value } })
        }
        getOptionLabel={(option) => option.label}
        value={
          selectOptions && value ? selectOptions.find((option) => option.value == value) : null
        }
        options={
          name.includes('project') ? selectOptions : sortDropdownOptions(selectOptions, 'value')
        }
        isDisabled={disabled}
        placeholder={optionText || 'Select an option'}
        noOptionsMessage={() => 'No options available'}
        styles={customStyles}
        onBlur={onBlur}
        {...(onkeydown ? { onKeyDown } : '')}
      />
      {isRequired && isError ? (
        <ErrorMessage
          validator={validator}
          value={value}
          label={name}
          validationString={validationString || ''}
        />
      ) : (
        <p />
      )}
    </>
  );
}

export function sortDropdownOptions(options: any, field = 'id') {
  return options?.sort(function (a, b) {
    if (a[field] && b[field]) {
      const valueA = a[field === 'id' ? 'value' : 'label'].toLowerCase();
      const valueB = b[field === 'id' ? 'value' : 'label'].toLowerCase();
      if (valueA < valueB) {
        return -1;
      }
      if (valueA > valueB) {
        return 1;
      }
      return 0;
    }
  });
}
