import React, { useState, useRef, useEffect, useMemo } from 'react';
import type { ChangeEvent } from 'react';
import DropDown from './inputs/Dropdown';
import GooglePlacesAutocompleteComp from './inputs/GooglePlacesAutocomplete';
import TextField from './NewLayout/Textfield';

interface IEditableTableCellProps {
  value: string;
  onEditComplete: (val: string) => void;
  type?: 'text' | 'number' | 'date' | 'time' | 'file';
  min?: string;
  isDropdown?: boolean;
  options?: { value: string; id: string | number }[];
  id?: string | number;
  apiInProgress?: boolean;
  setApiInProgress: (val: boolean) => void;
  displayValue?: string;
  isAddress?: boolean;
  name?: string;
}

function EditableTableCell({
  value,
  onEditComplete,
  type,
  min,
  isDropdown,
  options,
  id,
  apiInProgress,
  setApiInProgress,
  displayValue,
  isAddress,
  name
}: IEditableTableCellProps) {
  const [val, setVal] = useState<string | number>('');
  const ref = useRef(null);
  const latestVal = useRef<string | number>(val);

  useEffect(() => {
    setVal(id || value);
  }, [id, value]);

  useEffect(() => {
    latestVal.current = val;
  }, [val]);

  useEffect(() => {
    function handleClickOutside(event) {
      const isInput =
        event.target.matches('.input-element') || event.target.closest('.input-element');
      if (ref.current && !isInput) {
        handleOnBlur(isDropdown ?? false, event);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);

  const handleChange = ({ target }: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    setVal(target.value);
  };

  const handleDoubleClick = () => {
    setApiInProgress(true);
  };

  const handleOnBlur = (isValId: boolean, event: any) => {
    if (latestVal.current == val) {
      const v = isValId ? id : value;
      if (event.relatedTarget === ref.current) {
        return;
      }

      let refVal = latestVal.current;

      if (v != refVal) {
        refVal = refVal === '' ? ' ' : refVal.toString();
        onEditComplete(refVal);
      } else {
        setApiInProgress(false);
      }
    }
  };

  return apiInProgress ? (
    <div className="input-div" ref={ref}>
      {isDropdown ? (
        <DropDown
          name={name || 'editable-cell-dropdown'}
          value={val.toString()}
          onChange={(e) => handleChange(e)}
          onBlur={(e) => handleOnBlur(true, e)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handleOnBlur(true, e);
            }
          }}
          data={options}
        />
      ) : isAddress ? (
        <GooglePlacesAutocompleteComp
          value={val?.toString()}
          onChange={handleChange}
          onBlur={(e) => {
            handleOnBlur(false, e);
          }}
          fromEditableTableCell
          name={'editableCell'}
        />
      ) : (
        <TextField
          className={'form-control input-element'}
          type={type}
          min={min}
          name="editable-cell-input"
          value={val?.toString() ?? ''}
          onChange={(e) => handleChange(e)}
          onBlur={(e) => {
            handleOnBlur(false, e);
          }}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              handleOnBlur(false, e);
            }
          }}
        />
      )}
    </div>
  ) : (
    <div style={{ width: '100%', minHeight: '20px' }} onDoubleClick={handleDoubleClick}>
      {displayValue || value}
    </div>
  );
}
export default React.memo(
  EditableTableCell,
  (prevProps, nextProps) =>
    prevProps.apiInProgress === nextProps.apiInProgress && prevProps.value === nextProps.value
);
