import React, { useState } from 'react';
import { Autocomplete, TextField, useTheme } from '@mui/material';
import ErrorMessage from './ErrorMessage';

export default function SearchableDropdown(props: {
  options: any;
  onChange: any;
  onBlur?: any;
  label: string;
  value: string;
  inputName: string;
  validator?: any;
  required?: boolean;
  optionText?: string;
  size?: 'small' | 'medium';
  disabled?: boolean;
  onKeyDown?: any;
  isMultiple?: boolean;
}) {
  const {
    options,
    size,
    onChange,
    onBlur,
    label,
    value,
    inputName,
    required,
    validator,
    optionText,
    disabled,
    onKeyDown,
    isMultiple
  } = props;
  const theme = useTheme();
  const [val, setVal] = useState<string>('');

  const isError = validator?.current?.message(inputName, value, 'required');

  return (
    <>
      <Autocomplete
        {...(isMultiple ? { multiple: true } : {})}
        noOptionsText="No options available"
        options={options}
        value={options.find((option) => option.id == value) || null}
        onInputChange={(event, newInputValue) => {
          setVal(newInputValue);
        }}
        disableClearable
        onChange={(event, newValue: any) => {
          setVal(newValue?.value);
          onChange({ target: { name: inputName, value: newValue.id } });
        }}
        fullWidth
        inputValue={val}
        onBlur={onBlur}
        getOptionLabel={(option: any) => option.value}
        renderOption={(props, option) => (
          <li
            {...props}
            style={{
              background: option.color,
              color: option.color == '#335d87' ? '#fff' : '',
              marginBottom: '2px'
            }}>
            {option.value}
          </li>
        )}
        disabled={disabled}
        renderInput={(params) => (
          <div style={{ position: 'relative' }}>
            <TextField
              error={required && isError}
              sx={{
                borderRadius: '3px',
                paddingBottom: '16px',
                '& .MuiFilledInput-root': {
                  background: 'rgb(255, 255, 255)',
                  border: '1px solid #e2e2e2',
                  borderRadius: '3px',
                  '&:focus-within': {
                    border: `1px solid ${theme.palette.primary.main}`
                  }
                }
              }}
              {...params}
              type="text"
              name={inputName}
              label={label}
              fullWidth
              size={size || 'medium'}
              InputProps={{ ...params.InputProps, disableUnderline: true }}
              InputLabelProps={{
                shrink: true
              }}
              onKeyDown={onKeyDown}
              placeholder={optionText || 'Choose...'}
              disabled={disabled}
            />
            {required && (
              <span style={{ position: 'absolute', right: '10px', top: '5px', color: 'red' }}>
                *
              </span>
            )}
          </div>
        )}
      />
      {validator && <ErrorMessage label={inputName} value={value} validator={validator} />}
    </>
  );
}
